import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/schmachtl.cz/',
    tooltip: 'Facebook',
  },
 /* {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/redqinc/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/redqinc',
    tooltip: 'Twitter',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/company/redqinc/',
    tooltip: 'Linked In',
  },*/
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h1>O společnosti Schmachtl</h1>
        <h3></h3>
        <p></p>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage>

      <AboutDetails>
        <h1>Historie</h1>
        <p>
          Počátek firmy Schmachtl sahá až do prvorepublikového Československa.
          Dnem 1.1.1934 převzal ing. Karl Schmachtl (otec K.-H. Schmachtla,
          současného jednatele firmy Schmachtl KG v Rakousku) zastoupení firmy
          Vogel-Pumpen Stockerau pro celé teritorium Československa a zřídil i
          dílnu ve Znojmě. Činnost firmy byla ukončena roku 1939 a znovu
          obnovena ing. Franzem Schmachtlem (bratrem Karla Schmachtla) v roce
          1947 v Linci v Rakousku. Aktivity firmy Schmachtl do Československa
          byly obnoveny v roce 1978, kdy společnost obdržela několik zakázek pro
          velké české podniky prostřednictvím podniků zahraničního obchodu (PZO)
          a tato spolupráce s PZO pokračovala do roku 1990. V roce 1990 byla v
          Praze založena technická kancelář rakouské firmy Schmachtl KG, která
          navázala a dále rozšiřovala obchodní kontakty, které firma Schmachtl v
          tehdejším Československu měla. Dalším vývojovým krokem bylo v roce
          1992 založení společnosti Schmachtl CS s.r.o. jako dceřiné společnosti
          firmy Schmachtl KG Rakousko. V témže roce se firma přestěhovala z
          pronajaté místnosti v Praze-Šeberově do vlastního objektu -
          jednopatrové vily v Praze-Spořilově, kde vznikl i první malý sklad
          zboží. Postupné rozrůstání firmy si vyžádalo zaplnění vily do
          nejposlednějšího místa a pronájem dalších dvou skladů. Rok 1997 se
          zapsal zlatým písmem, neboť firma Schmachtl se přestěhovala do zcela
          nově vybudovaného objektu na jižním okraji Prahy, do Vestce u Prahy.
          Nejmodernější technologií zařízená administrativní budova a sklad o
          rozloze téměř 3000 m2 denně přispívají ke zlepšení služeb zákazníků a
          tvoří výborné pracovní prostředí pro 56 zaměstnanců firmy. A protože
          mladá dynamická firma s mottem: "maximální spokojenost zákazníka"
          nadále úspěšně expanduje nejen v České republice, dochází v říjnu roku
          1999 k založení sesterské společnosti Schmachtl SK v Bratislavě a dnem
          1.1.2000 se pražská kancelář se svým 41- členným týmem přejmenovává na
          Schmachtl CZ spol. s r.o.. 1.1.2002 byla založena technická kancelář
          ve Zlíně; nyní je 50 zaměstanců v Praze (ve Vestci) a 6 ve Zlíně. Od
          roku 2003 již Schmachtl CZ není dceřinou společností Schmachtl KG, ale
          spolu s Schmachtl SK je dceřinou společností nově založené firmy
          K.H.Schmachtl Holding GmbH.
        </p>
        <h1>Filozofie</h1>
        <p>
          Cílem našeho snažení je vybudovat prosperující firmu plně vyhovující
          potřebám našich zákazníků. Našim úkolem je vytvoření úzkého kontaktu s
          klientem a zaručit naprostou spokojenost všech zákazníků. Rádi Vám
          pomůžeme při řešení různých aplikací našich komponentů a zařízení.
          Firma Schmachtl vysílá své zástupce přímo na pracoviště, kde Vám v
          praxi jsou zkušenými poradci pro naše výrobky. Zázemí pro konzultace
          ve firmě tvoří oddělení technické podpory. Klademe důraz na uzavření
          kruhu s našimi klienty, protože jen v případě rad a podnětů dokážeme
          velice rychle a přesně reagovat na požadavky trhu a uspokojit naše
          zákazníky. Partnerský vztah k zákazníkům, neustálá inovace, nejvyšší
          kvalita, rychlé dodávky a nejlepší poměr mezi cenou a výkonem umožnily
          náš současný úspěch. To pro nás představuje závazek do budoucnosti,
          plnit a poznávat požadavky a přání našich zákazníků. V době
          elektronického obchodu se snažíme vybudovat moderní objednávkový
          systém pro naše stálé zákazníky na bázi on-line objedávek přímo z
          našeho skladu a interaktivní WEB-shop, kde bude moci každý uživatel
          internetu nakoupit zboží pro elektrotechniku a elektroinstalace. Jako
          nejvyšší firemní hodnoty ve všech aspektech svého podnikání staví
          společnost Schmachtl čestnost, integritu, poctivost a zaměření na
          zákazníka. V souladu se zájmy společnosti Schmachtl je dodržování
          ekologických a bezpečnostních opatření. Přejeme si, aby všichni naši
          zákazníci plně využili všech výhod, které jim naše firma nabízí.
        </p>
        <h1>Oblast činnosti</h1>
        <p>
          Společnost Schmachtl je výhradním zástupcem světových výrobců v
          oblasti elektrotechniky, elektroniky a strojírenství. Své partnery k
          obchodní spolupráci vybírá velmi pečlivě, mezi stěžejní kritéria patří
          spolehlivost, vysoká úroveň kvality, servis a dostupnost náhradních
          dílů pro danou oblast. Partnerské firmy, které dodávají své výrobky
          prostřednictvím společnosti Schmachtl jsou uznávanými výrobci
          komponentů a zařízení pro užití ve všech průmyslových oblastech. Firma
          Schmachtl vyvíjí své aktivity především v těchto oblastech:
          strojírenství (čerpadla, vývěvy a kompresory, kapalinové filtry,
          hřídelové spojky, kluzná ložiska, komponenty pro hydraulické výtahy)
          senzorika, automatizace (optická čidla, čtečky čárového kódu, světelné
          závory, koncové spínače, snímače polohy, programovatelné řídící
          jednotky PLC) pohony a energetické zdroje (motory, precizní motorky,
          frekvenční měniče, záložní zdroje - UPS) rozváděčová technika (řadové
          svorky, průmyslové konektory, svorky a konektory do plošných spojů,
          rozváděčové kabelové kanály, sběrnicový systém, kabelová oka, dutinky,
          rozváděčové skříně včetně komponent, příslušenství a nářadí)
          elektroinstalace (kanálové instalační systémy, upevňovací a úložné
          systémy pro elektromontáže, zásuvkový a spínačový program, konektorové
          instalační systémy) zdvihací a jeřábová technika (závěsné a křížové
          ovladače, pohyblivé přívody energie, kroužkové sběrače, kabelové
          vodící řetězy, koncové spínače, optická a akustická signalizace)
          průmyslové záložní zdroje od 0,3 do 4800 kVA
        </p>
        <p>
          Firma je úspěšná v tom, že neustále rozšiřuje portfolio zastupovaných
          firem, vytvořila si postavení, kdy zahraniční firmy se sami na nás
          obracejí s žádostí o zastupování a na českém trhu jsme v naší branži
          firmou, kterou každý zná. Známé reference: jsme partnery firmy
          Transgas, ČSL (Česká správa letišť), dodávali jsme na rekonstrukci
          Kongresového centra v Praze, na stavbu Sazka Arény, apod. (Referencí
          můžeme podat i daleko více, ale asi je lepší pár známých firem, resp.
          staveb než dlohý seznam). Komunikační přístupy - kromě www stránek
          prolinkovaných na zastupované firmy máme internetový portál, kde
          zákazníci mohou sledovat stav svých objednávek, faktur, dotazovat stav
          našeho skladu, zasílat objednávky i ve formátu XML, kdy je provedeno
          automatické zpracování včetně potvrzení objednávky, a mnoho dalších
          informací o jejich obchodu s námi. Dále máme v našem
          ekonomicko-informačním systému zaveden CRM (Customer relation
          management). Z hlediska obratu chceme samozřejmě dále růst a hlavně
          ještě více posilovat naše postavení na trhu, abychom byli pro naše
          zákazníky co nejsilnější partner - proto se snažíme zákazníkovi
          nabídnout řešení co nejvíce jeho požadavků - k tomu slouží naše široká
          paleta nabízených výrobků a služeb. Investice byly především v r. 1997
          koupě pozemku, stavba administrativní budovy a prvního skladu, v r.
          2003 stavba druhého skladu. Do budoucna - možná koupě vlastního
          objektu ve Zlíně - ale to není zatím v aktuálním plánu. Zaměstnanost -
          v budoucnu bude našim cílem ještě více zefektivnit práci, takže růst
          počtu zaměstnaců by měl být menší v porovnání s růstem obratu.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
